export const invoiceTableHeader = [
  {
    title: 'Ledger ID',
    dataIndex: 'ledgerId',
    key: 'ledgerId',
    sorter: (a, b) => a.ledgerId - b.ledgerId,
    fixed: 'left',
  },
  {
    title: 'Invoice Building',
    dataIndex: 'invBuilding',
    key: 'invBuilding',
  },
  {
    title: 'External ID',
    dataIndex: 'ExternalID',
    key: 'ExternalID',
    sorter: (a, b) => a.ExternalID.localeCompare(b.ExternalID),
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    sorter: (a, b) => a.customer.localeCompare(b.customer),
  },
  {
    title: 'Invoice #',
    dataIndex: 'invoiceNo',
    key: 'invoiceNo',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: 'PO #',
    dataIndex: 'poNo',
    key: 'poNo',
  },
  {
    title: 'Memo',
    dataIndex: 'memo',
    key: 'memo',
    sorter: (a, b) => a.memo.localeCompare(b.memo),
  },
  {
    title: 'Beginning balance',
    dataIndex: 'begBal',
    key: 'begBal',
  },
  {
    title: 'Terms',
    dataIndex: 'Terms',
    key: 'Terms',
  },
  {
    title: 'Sales Rep',
    dataIndex: 'salesRep',
    key: 'salesRep',
    sorter: (a, b) => a.salesRep.localeCompare(b.salesRep),
  },
  {
    title: 'Department',
    dataIndex: 'Dept',
    key: 'Dept',
    sorter: (a, b) => a.Dept.localeCompare(b.Dept),
  },

  {
    title: 'Class',
    dataIndex: 'Class',
    key: 'Class',
    sorter: (a, b) => a.Class.localeCompare(b.Class),
  },
  {
    title: 'Location',
    dataIndex: 'Location',
    key: 'Location',
  },
  {
    title: 'Currency',
    dataIndex: 'Currency',
    key: 'Currency',
  },
  {
    title: 'Exchange Rate',
    dataIndex: 'eRate',
    key: 'eRate',
  },
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
  },
  {
    title: 'Item: Description',
    dataIndex: 'itemDesc',
    key: 'itemDesc',
  },
  {
    title: 'Item: Rate',
    dataIndex: 'itemRate',
    key: 'itemRate',
    sorter: (a, b) => a.itemRate - b.itemRate,
  },
  {
    title: 'Item: Amount',
    dataIndex: 'itemAmount',
    key: 'itemAmount',
    sorter: (a, b) => a.itemAmount - b.itemAmount,
  },
  {
    title: 'Item: Tax Code',
    dataIndex: 'itemTaxCode',
    key: 'itemTaxCode',
    sorter: (a, b) => a.itemTaxCode.localeCompare(b.itemTaxCode),
  },
  {
    title: 'Item: Tax Amount',
    dataIndex: 'itemTaxAmount',
    key: 'itemTaxAmount',
    sorter: (a, b) => a.itemTaxAmount - b.itemTaxAmount,
  },
  {
    title: 'Item: Gross Amount',
    dataIndex: 'itemGrossAmount',
    key: 'itemGrossAmount',
    sorter: (a, b) => a.itemGrossAmount - b.itemGrossAmount,
  },
  {
    title: 'Item: Department',
    dataIndex: 'itemDept',
    key: 'itemDept',
    sorter: (a, b) => a.itemDept.localeCompare(b.itemDept),
  },
  {
    title: 'Item: Class',
    dataIndex: 'itemClass',
    key: 'itemClass',
    sorter: (a, b) => a.itemClass.localeCompare(b.itemClass),
  },
  {
    title: 'Item: Apply Witdholding Tax',
    dataIndex: 'itemApplyWHtax',
    key: 'itemApplyWHtax',
    sorter: (a, b) => a.itemApplyWHtax - b.itemApplyWHtax,
  },
  {
    title: 'Item: Witdholding Tax Rate',
    dataIndex: 'itemWHTaxRate',
    key: 'itemWHTaxRate',
  },
  {
    title: 'Item: Witdholding Tax Base Amount',
    dataIndex: 'itemWHTaxBaseAmt',
    key: 'itemWHTaxBaseAmt',
    sorter: (a, b) => a.itemWHTaxBaseAmt - b.itemWHTaxBaseAmt,
  },
  {
    title: 'Subsidiary',
    dataIndex: 'subsidiary',
    key: 'subsidiary',
  },
  {
    title: 'Approval Status',
    dataIndex: 'ApprovalStatus',
    key: 'ApprovalStatus',
  },
];
