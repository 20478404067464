import axios from 'axios';
import { API_CALL_ERROR, LOGIN_ENDPOINT } from './_constants';

const APIEndpoint = 'https://mytownph.com/pms_netsuite';
const GET_INVOICE_ENDPOINT = APIEndpoint + '/invoice';
const POST_INVOICE_ENDPOINT = APIEndpoint + '/postInvoice.php';
const POST_CASH_SALE_ENDPOINT = APIEndpoint + '/postCashSale.php';

const SEARCH_QUERY_ENDPOINT = APIEndpoint + '/searchValueAPI.php';

export async function getIdByQuery(table, field, value) {
  const payload = {
    table: table,
    field: field,
    value: value,
  };
  try {
    const { data } = await axios.post(SEARCH_QUERY_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postNetSuiteInvoice(payload) {
  try {
    const { data } = await axios.post(POST_INVOICE_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function postNetSuiteCashSale(payload) {
  try {
    const { data } = await axios.post(POST_CASH_SALE_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
