import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_APPROVED_INVOICES_ENDPOINT,
  GET_INVOICES_ENDPOINT,
  SAVE_INVOICES_ENDPOINT,
  SAVE_UPLOADED_INVOICES_ENDPOINT,
} from './_constants';

export async function getInvoices(fromDate, toDate, building) {
  const qs = require('qs');
  try {
    const { data } = await axios.post(
      GET_INVOICES_ENDPOINT,
      qs.stringify({
        from: fromDate,
        to: toDate,
        building: building,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function saveInvoices(userId, inventoryList) {
  const payload = {
    userId: userId,
    invList: inventoryList,
  };
  try {
    const { data } = await axios.post(SAVE_INVOICES_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getApprovedInvoices(fromDate, toDate, building) {
  const qs = require('qs');
  try {
    const { data } = await axios.post(
      GET_APPROVED_INVOICES_ENDPOINT,
      qs.stringify({
        from: fromDate,
        to: toDate,
        building: building,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function saveUploadedInvoices(inventoryList) {
  const payload = {
    invList: inventoryList,
  };
  try {
    const { data } = await axios.post(
      SAVE_UPLOADED_INVOICES_ENDPOINT,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
