import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  ConfirmationModal,
  MessageModal,
  Selection,
} from '../../../components';
import { UserContext } from '../../../utils/contexts/UserContext';
import { Card, DatePicker, Descriptions, Table } from 'antd';
import { cashSaleTableHeader } from '../../../utils/tableData/CashSalesTableHeader';
import { saveCashSales } from '../../../utils/api/CashSalesAPI';

export default function CashSalesForApproval() {
  const {
    profile,
    cashSalesForApproval,
    loadCashSalesForApproval,
    buildings,
    loadBuildings,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [localCashSaleList, setLocalCashSaleList] = useState([]);
  const [localBuildingsList, setLocalBuildingsList] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageModalBody, setMessageModalBody] = useState('');

  const data = localCashSaleList.map((data, index) => {
    return { ...data, key: index };
  });

  const getItemRateTotal = () => {
    const total = localCashSaleList
      .filter((item) => item.itemRate !== null)
      .reduce(
        (acc, item) => acc + parseFloat(item.itemRate.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const getItemAmountTotal = () => {
    const total = localCashSaleList
      .filter((item) => item.itemAmount !== null)
      .reduce(
        (acc, item) => acc + parseFloat(item.itemAmount.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const getItemTaxAmountTotal = () => {
    const total = localCashSaleList
      .filter((item) => item.itemTaxAmount !== null)
      .reduce(
        (acc, item) => acc + parseFloat(item.itemTaxAmount.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const getItemGrossAmountTotal = () => {
    const total = localCashSaleList
      .filter((item) => item.itemGrossAmount !== null)
      .reduce(
        (acc, item) => acc + parseFloat(item.itemGrossAmount.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const handleApprove = async () => {
    setIsLoading(true);
    setIsConfirmationModalOpen(false);
    let cashSalesStatus = [];

    localCashSaleList.forEach((record) => {
      const recordMatched = selectedRows.find(
        (selected) => selected.invoiceId === record.invoiceId
      );
      if (recordMatched !== null && recordMatched !== undefined) {
        const dataRecord = {
          invoiceId: recordMatched.invoiceId,
          invBuilding: recordMatched.invBuilding,
          invoiceNumber: recordMatched.invoiceNumber,
          externalId: recordMatched.ExternalID,
          month: recordMatched.month,
          invoiceDate: recordMatched.invoiceDate,
          status: 1,
        };
        cashSalesStatus.push(dataRecord);
      }
      // else {
      //   const dataRecord = {
      //     invoiceId: record.invoiceId,
      //     invBuilding: record.invBuilding,
      //     invoiceNumber: record.invoiceNumber,
      //     externalId: record.ExternalID,
      //     month: record.month,
      //     invoiceDate: record.invoiceDate,
      //     status: 2,
      //   };
      //   cashSalesStatus.push(dataRecord);
      // }
    });

    const response = await saveCashSales(
      parseInt(profile.userId),
      cashSalesStatus
    );

    if (response.isSuccessful) {
      resetData();
      setMessageModalBody('Cash Sales approval success.');
      setIsMessageModalOpen(true);
    } else {
      setMessageModalBody(
        'An error occured. Please contact system administrator immediately.'
      );
      setIsMessageModalOpen(true);
    }

    setIsLoading(false);
  };

  const resetData = () => {
    setSelectedBuilding('');
    setSelectedFromDate('');
    setSelectedToDate('');

    setLocalCashSaleList([]);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    setLocalCashSaleList(cashSalesForApproval);
  }, [cashSalesForApproval]);

  useEffect(() => {
    setLocalBuildingsList(buildings);
  }, [buildings]);

  useEffect(() => {
    if (buildings.length === 0) {
      loadBuildings();
    }
  }, []);

  return (
    <Container className="pt-3">
      <h2>Cash Sales - For Approval</h2>
      <hr />
      <Row>
        <Col xs={3}>
          <Selection
            title={'Building'}
            disabled={isLoading}
            value={selectedBuilding}
            onChange={(e) => {
              setSelectedBuilding(e.target.value);
            }}
            options={localBuildingsList.map((building) => ({
              value: building.buildingCode,
              name: building.buildingName,
            }))}
          />
        </Col>
        <Col>
          <DatePicker.RangePicker
            onChange={(dates) => {
              const [start, end] = dates;
              setSelectedFromDate(start.format('YYYY-MM-DD'));
              setSelectedToDate(end.format('YYYY-MM-DD'));
            }}
            format="YYYY-MM-DD"
            placeholder={['Start Date', 'End Date']}
            style={{ height: '40px', width: '100%' }}
            disabled={isLoading}
          />
        </Col>
        <Col>
          <Button
            variant="secondary"
            disabled={
              isLoading ||
              selectedBuilding === '' ||
              selectedFromDate === '' ||
              selectedToDate === ''
            }
            onClick={async () => {
              setIsLoading(true);
              setSelectedRows([]);
              setSelectedRowKeys([]);
              await loadCashSalesForApproval(
                selectedFromDate,
                selectedToDate,
                selectedBuilding
              );
              setIsLoading(false);
            }}
            className="mb-3 w-100"
          >
            {isLoading ? 'Loading...' : 'Filter'}
          </Button>
        </Col>
        <Row className="pe-0">
          <Col className="w-100 text-center pe-0">
            {selectedRows.length > 0 && (
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={() => setIsConfirmationModalOpen(true)}
                className="mb-3 w-100 pms-bg"
              >
                <strong>{isLoading ? 'Saving...' : 'Approve!'}</strong>
              </Button>
            )}
          </Col>
        </Row>
      </Row>
      <Table
        columns={cashSaleTableHeader}
        dataSource={data}
        title={() => 'For Approval'}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRows(newSelectedRows);
            setSelectedRowKeys(newSelectedRowKeys);
          },
        }}
      />
      {localCashSaleList.length !== 0 && (
        <Row>
          <Card
            loading={isLoading}
            className="mt-2"
            hoverable
            title="Total details"
          >
            <Descriptions
              bordered
              layout="vertical"
              size="small"
              column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Item: Rate">
                {getItemRateTotal()}
              </Descriptions.Item>
              <Descriptions.Item label="Item: Amount">
                {getItemAmountTotal()}
              </Descriptions.Item>
              <Descriptions.Item label="Item: Tax Amount">
                {getItemTaxAmountTotal()}
              </Descriptions.Item>
              <Descriptions.Item label="Item: Gross Amount">
                {getItemGrossAmountTotal()}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Row>
      )}
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setIsConfirmationModalOpen}
        title={'Confirm Cash Sales approval.'}
        body={
          'NOTE: All checked rows will be APPROVED and will be uploadable to NetSuite.'
        }
        handleConfirm={handleApprove}
      />
      <MessageModal
        isModalOpen={isMessageModalOpen}
        setIsModalOpen={setIsMessageModalOpen}
        title={'Invoice Approval'}
        body={messageModalBody}
      />
    </Container>
  );
}
