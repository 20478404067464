import './App.css';
import './Colors.css';

//Start of fonts
import './assets/fonts/Poppins/Poppins-Light.ttf';
import './assets/fonts/Poppins/Poppins-Thin.ttf';
import './assets/fonts/Poppins/Poppins-Regular.ttf';
import './assets/fonts/Poppins/Poppins-SemiBold.ttf';
import './assets/fonts/Poppins/Poppins-Medium.ttf';
import './assets/fonts/Poppins/Poppins-Bold.ttf';
// End of fonts

import { createContext, useEffect, useState } from 'react';
import { BrowserRouter, HashRouter, useSearchParams } from 'react-router-dom';
import MainRoute from './utils/routers/MainRoute';
import AuthRoute from './utils/routers/AuthRoute';
import { UserProvider } from './utils/contexts/UserContext';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const globalContextValues = {
    isLoggedIn,
    setIsLoggedIn,
  };

  return (
    <GlobalContext.Provider value={globalContextValues}>
      <UserProvider>
        <HashRouter>
          <div className="App">
            {isLoggedIn ? <MainRoute /> : <AuthRoute />}
          </div>
        </HashRouter>
      </UserProvider>
    </GlobalContext.Provider>
  );
}

export default App;
export const GlobalContext = createContext();
