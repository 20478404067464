import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/contexts/UserContext';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ConfirmationModal, MessageModal, Selection } from '../../components';
import { invoiceTableHeader } from '../../utils/tableData/InvoiceTableHeader';
import { Card, DatePicker, Descriptions, Table } from 'antd';
import { saveUploadedInvoices } from '../../utils/api/InvoiceAPI';
import { getIdByQuery, postNetSuiteInvoice } from '../../utils/api/NetSuiteAPI';

export default function InvoiceUploading() {
  const {
    profile,
    approvedInvoicesList,
    loadApprovedInvoices,
    buildings,
    loadBuildings,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [localApprovedInvoices, setLocalApprovedInvoices] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');

  const [localBuildingsList, setLocalBuildingsList] = useState([]);

  const [currentRecordNumber, setCurrentRecordNumber] = useState(0);

  // For message modal.
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState('');

  const data = localApprovedInvoices.map((data, index) => {
    return { ...data, key: index };
  });

  const removeCommaAndConvertToFloat = (numberString) => {
    // Remove commas from the number string
    if (
      typeof numberString === 'number' ||
      numberString === '0.00' ||
      numberString === '' ||
      numberString === null ||
      numberString === undefined
    ) {
      return numberString;
    }
    var stringWithoutCommas = numberString.replace(/,/g, '');

    // Convert the modified string to a float
    var floatNumber = parseFloat(stringWithoutCommas);

    // Check if the conversion resulted in a valid float
    if (!isNaN(floatNumber)) {
      return floatNumber;
    } else {
      // Handle the case when the string does not represent a valid number
      return null; // or any other appropriate value or error handling mechanism
    }
  };

  const getItemRateTotal = () => {
    const total = localApprovedInvoices
      .filter((item) => item.itemRate !== null && item.itemRate.includes(','))
      .reduce(
        (acc, item) => acc + parseFloat(item.itemRate.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const getItemAmountTotal = () => {
    const total = localApprovedInvoices
      .filter(
        (item) => item.itemAmount !== null && item.itemAmount.includes(',')
      )
      .reduce(
        (acc, item) => acc + parseFloat(item.itemAmount.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const getItemTaxAmountTotal = () => {
    const total = localApprovedInvoices
      .filter(
        (item) =>
          item.itemTaxAmount !== null && item.itemTaxAmount.includes(',')
      )
      .reduce(
        (acc, item) => acc + parseFloat(item.itemTaxAmount.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const getItemGrossAmountTotal = () => {
    const total = localApprovedInvoices
      .filter(
        (item) =>
          item.itemGrossAmount !== null && item.itemGrossAmount.includes(',')
      )
      .reduce(
        (acc, item) => acc + parseFloat(item.itemGrossAmount.replace(/,/g, '')),
        0
      );
    return total.toLocaleString('en-US', { style: 'decimal' });
  };

  const handleUploadToNetSuite = async () => {
    setIsLoading(true);
    setIsConfirmationModalOpen(false);
    let uploadedInvoices = [];
    let errorInvoices = [];

    for (const [index, record] of localApprovedInvoices.entries()) {
      setCurrentRecordNumber(index + 1);
      const customer = await getIdByQuery(
        'customer',
        'entityId',
        record.customer
      );
      if (customer.count === 1) {
        const netsuiteRecord = {
          account: {
            refName: 'PULS110301 Receivables : Trade Receivables',
          },
          // approvalStatus: {
          //   refName: 'Approved',
          // },
          class: {
            refName: record.Class,
          },
          currency: {
            refName: record.Currency,
          },
          // custbody_neo_inv_type: {
          //   refName: 'Revenue',
          // },
          customForm: { id: '161' },
          // date: record.date,
          department: {
            refName: record.Dept,
          },
          // dueDate: record.date,
          entity: {
            type: 'customer',
            id: customer.items[0].id,
          },
          exchangeRate: record.eRate,
          externalId: record.ExternalID,
          location: {
            refName: record.Location,
          },
          memo: record.memo,
          tranDate: record.date,
          items: [
            {
              amount: removeCommaAndConvertToFloat(record.itemAmount),
              class: {
                refName: record.itemClass,
              },
              department: {
                refName: record.itemDept,
              },
              description: record.itemDesc,
              item: {
                refName: record.item,
              },
              quantity: 1,
              rate: removeCommaAndConvertToFloat(record.itemRate),
              // taxAmount: record.itemTaxAmount,
              // taxCode: {
              //   type: 'taxCode',
              //   refName: record.itemTaxCode,
              // },
              // taxIsWithholding: 'FALSE',
            },
          ],
        };
        const netsuiteUpload = await postNetSuiteInvoice(netsuiteRecord);
        if (netsuiteUpload.status === 200) {
          const uploadedInvoice = { ledgerId: record.ledgerId };
          uploadedInvoices.push(uploadedInvoice);
        } else {
          const errorDetail = netsuiteUpload['o:errorDetails'][0]['detail'];
          record['errorDetail'] = errorDetail;
          record.itemAmount = removeCommaAndConvertToFloat(record.itemAmount);
          record.itemRate = removeCommaAndConvertToFloat(record.itemRate);
          record.itemGrossAmount = removeCommaAndConvertToFloat(
            record.itemGrossAmount
          );
          record.itemTaxAmount = removeCommaAndConvertToFloat(
            record.itemTaxAmount
          );
          errorInvoices.push(record);
        }
      } else {
        record['errorDetail'] = 'Customer not found.';
        record.itemAmount = removeCommaAndConvertToFloat(record.itemAmount);
        record.itemRate = removeCommaAndConvertToFloat(record.itemRate);
        record.itemGrossAmount = removeCommaAndConvertToFloat(
          record.itemGrossAmount
        );
        record.itemTaxAmount = removeCommaAndConvertToFloat(
          record.itemTaxAmount
        );
        errorInvoices.push(record);
      }
    }

    if (uploadedInvoices.length !== 0) {
      const response = await saveUploadedInvoices(uploadedInvoices);
      if (response.isSuccessful) {
        resetData();
        setModalBody(
          `${uploadedInvoices.length} record(s) out of ${localApprovedInvoices.length} is uploaded to NetSuite.`
        );
        setIsMessageModalOpen(true);
      }
    } else {
      setModalBody('No record is uploaded.');
      setIsMessageModalOpen(true);
    }
    if (errorInvoices !== 0) {
      const headers = Object.keys(errorInvoices[0]); // Extract headers from the first object

      const csvContent = [
        headers.join(','), // Create the header row
        ...errorInvoices.map((obj) => headers.map((key) => obj[key]).join(',')), // Create data rows
      ].join('\n'); // Join all rows with line breaks

      // Create a blob with the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv' });

      // Generate a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'errorResults.csv';

      // Trigger the download
      downloadLink.click();
    }
    setIsLoading(false);
  };

  const resetData = () => {
    setSelectedBuilding('');
    setSelectedFromDate('');
    setSelectedToDate('');

    setLocalApprovedInvoices([]);
  };

  useEffect(() => {
    setLocalApprovedInvoices(approvedInvoicesList);
  }, [approvedInvoicesList]);

  useEffect(() => {
    setLocalBuildingsList(buildings);
  }, [buildings]);

  useEffect(() => {
    if (buildings.length === 0) {
      loadBuildings();
    }
  }, []);

  return (
    <Container className="pt-3">
      <h2>Invoices - Upload to NetSuite</h2>
      <hr />
      <Row>
        <Col xs={3}>
          <Selection
            title={'Building'}
            disabled={isLoading}
            value={selectedBuilding}
            onChange={(e) => {
              setSelectedBuilding(e.target.value);
            }}
            options={localBuildingsList.map((building) => ({
              value: building.buildingCode,
              name: building.buildingName,
            }))}
          />
        </Col>
        <Col>
          <DatePicker.RangePicker
            onChange={(dates) => {
              const [start, end] = dates;
              setSelectedFromDate(start.format('YYYY-MM-DD'));
              setSelectedToDate(end.format('YYYY-MM-DD'));
            }}
            format="YYYY-MM-DD"
            placeholder={['Start Date', 'End Date']}
            style={{ height: '40px', width: '100%' }}
            disabled={isLoading}
          />
        </Col>
        <Col>
          <Button
            variant="secondary"
            disabled={
              isLoading ||
              selectedBuilding === '' ||
              selectedFromDate === '' ||
              selectedToDate === ''
            }
            onClick={async () => {
              setIsLoading(true);
              await loadApprovedInvoices(
                selectedFromDate,
                selectedToDate,
                selectedBuilding
              );
              setIsLoading(false);
            }}
            className="mb-3 w-100"
          >
            {isLoading ? 'Loading...' : 'Process'}
          </Button>
        </Col>
        <Row className="pe-0">
          <Col className="w-100 text-center pe-0">
            {localApprovedInvoices.length > 0 && (
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={() => setIsConfirmationModalOpen(true)}
                className="mb-3 w-100 pms-bg"
              >
                <strong>
                  {isLoading
                    ? `Uploading ${currentRecordNumber} of ${localApprovedInvoices.length} records.`
                    : 'Upload to NetSuite!'}
                </strong>
              </Button>
            )}
          </Col>
        </Row>
      </Row>
      <Table
        columns={invoiceTableHeader}
        dataSource={data}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
      {localApprovedInvoices.length !== 0 && (
        <Row>
          <Card
            loading={isLoading}
            className="mt-2"
            hoverable
            title="Total details"
          >
            <Descriptions
              bordered
              layout="vertical"
              size="small"
              column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Item: Rate">
                {getItemRateTotal()}
              </Descriptions.Item>
              <Descriptions.Item label="Item: Amount">
                {getItemAmountTotal()}
              </Descriptions.Item>
              <Descriptions.Item label="Item: Tax Amount">
                {getItemTaxAmountTotal()}
              </Descriptions.Item>
              <Descriptions.Item label="Item: Gross Amount">
                {getItemGrossAmountTotal()}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Row>
      )}
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setIsConfirmationModalOpen}
        title={'Upload to NetSuite?'}
        body={
          'Please confirm that all the data included on the table should be uploaded to NetSuite. REVERT IS NOT an option.'
        }
        handleConfirm={handleUploadToNetSuite}
        confirmationVariant={'danger'}
      />
      <MessageModal
        isModalOpen={isMessageModalOpen}
        setIsModalOpen={setIsMessageModalOpen}
        title={'NetSuite Invoice'}
        body={modalBody}
      />
    </Container>
  );
}
