import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function MessageModal({
  isModalOpen,
  setIsModalOpen,
  title,
  body,
}) {
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      {body && <Modal.Body>{body}</Modal.Body>}
      <Modal.Footer>
        <Button variant="success" onClick={() => setIsModalOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
